<template>
<div class="video-wrapper">
  <div class="start" v-on:click="start"></div>
  <div class="info" v-on:click="info"></div>
    <video class="video" id="video" ref="video" crossOrigin="anonymous" muted playsinline autoplay loop>
      <source src="video/Intro-090721.mp4" type="video/mp4" />
    </video>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Intro',

  /*
  setup () {
    const video = ref(null)

    onMounted(() => {
      // the DOM element will be assigned to the ref after initial render
      console.log(video.value) // <div>This is a root element</div>
      console.log(JSON.stringify(video.value))
      const a = video.value as HTMLVideoElement
      a.play()
    })

    return {
      video
    }
  },
  */

  mounted () {
    // console.log('XXXXXXX')
    // console.log(this.$refs.video)
    setTimeout(() => {
      (this.$refs.video as HTMLVideoElement).play()
    }, 400)
    console.log('XXXXXXXXX')
    // console.log(new Date())
    // console.log(this.video)
    // this.video.play()
  },

  methods: {
    start () {
      this.$router.push('tictactoe')
    },

    info () {
      this.$router.push('sources')
    }
  }
})
</script>
