
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Intro',

  /*
  setup () {
    const video = ref(null)

    onMounted(() => {
      // the DOM element will be assigned to the ref after initial render
      console.log(video.value) // <div>This is a root element</div>
      console.log(JSON.stringify(video.value))
      const a = video.value as HTMLVideoElement
      a.play()
    })

    return {
      video
    }
  },
  */

  mounted () {
    // console.log('XXXXXXX')
    // console.log(this.$refs.video)
    setTimeout(() => {
      (this.$refs.video as HTMLVideoElement).play()
    }, 400)
    console.log('XXXXXXXXX')
    // console.log(new Date())
    // console.log(this.video)
    // this.video.play()
  },

  methods: {
    start () {
      this.$router.push('tictactoe')
    },

    info () {
      this.$router.push('sources')
    }
  }
})
